import React from "react"
import { Box } from "@chakra-ui/react"
import loadable from "@loadable/component"

// const Header = loadable(() => import("./Header"))
const Footer = loadable(() => import("./Footer"))

const Layout = ({ children }) => {
  return (
    <>
      {/* <Header /> */}
      <Box as="main" width="full">
        {children}
      </Box>
      <Footer />
    </>
  )
}

export default Layout
